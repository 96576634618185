<template>
    <v-card
        class="mx-auto mt-6"
        max-width="700"
    >
        <v-card>
            <v-card-text>Usted se encuentra: <strong>Pagando un Recaudo</strong></v-card-text>
        </v-card>
        <v-stepper alt-labels v-model="e1">
            <v-stepper-header>
            <v-stepper-step
                :rules="[() => stepOne]"
                editable
                step="1"
                :complete="e1 > 1"
                @click="validateOne = true"
            >
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                <One @valid="validStep" :validate="validateOne" :default="editObject"></One>

                <v-btn
                color="primary"
                @click="receiveData"
                :disabled="invalidForm"
                >
                Pagar Recaudo
                </v-btn>

                <v-btn @click="rollbackLink" text>
                Cancelar
                </v-btn>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import Swal from "sweetalert2";

import One from "./form/pay.vue";

import Storage from "@/modules/storage";

const store = new Storage();

  export default {
    data () {
      return {
        data: {},
        editObject: {},
        e1: 1,
        invalidForm: true,
        stepOne: true,
        validateOne: false
      }
    },
    components: {
        One
    },
    async mounted() {
        const collection = await store.getData("collection", this.$route.params.object_id);
        if (collection.contributor) {
            collection.collectionType = 'Aportante';
        } else if (collection.affiliate) {
            collection.collectionType = 'Afiliado';
        }
        this.editObject = collection;
    },
    methods: {
        validStep(value) {
            if (value.step === 1) {
                this.stepOne = value.valid
            }
            for (let [key, value] of Object.entries(value.data)) {
                this.data[key] = value;
            }
            if (this.stepOne) {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        },
        async receiveData() {
            Swal.fire({
                icon: "warning",
                title: "Pagando Recaudo",
                html:
                "Por favor espere mientras se paga el Recaudo</b> " +
                '<progress id="progressBar" value="0" max="100"></progress>',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
            });
            try {
                const fileData = this.data.payrollFile;
                const formData = new FormData();
                const progressBar = document.getElementById("progressBar");
                const percentUpdate = (loaded, total, percent) => {
                    percent -= 5;
                    progressBar.innerText = percent.toString() + "%";
                    progressBar.style.width = percent.toString() + "%";
                };

                this.data = {
                    _id: this.$route.params.object_id,
                    payrollCode: this.data.payrollCode
                }
                await store.updateData("collection", [this.data], true, "misalud");

                formData.append("_id", this.data._id);
                formData.append("payroll", fileData, 'Planilla.pdf');
                await store.uploadFile(
                    "collection",
                    this.data._id,
                    "misalud/collection/upload",
                    formData,
                    percentUpdate
                )

                Swal.fire({
                icon: "success",
                title: "Recaudo Pagado"
                }).then(() => {
                    this.$router.go(-1);
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error al pagar el Recaudo"
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        rollbackLink() {
            Swal.fire({
                title: "¿Abandonar el Formulario?",
                showCancelButton: true,
                confirmButtonText: `Abandonar`,
                cancelButtonText: "Quedarse"
            }).then(result => {
                if (result.isConfirmed) {
                this.$router.go(-1);
                }
            });
        }
    },
  }
</script>