<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Información de la Planilla</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-select
                v-model="collectionType"
                :items="collectionTypes"
                :rules="collectionTypeRules"
                label="Tipo de Recaudo"
                :disabled="blockMode"
                required
            ></v-select>

            <v-autocomplete
                v-if="collectionType === 'Aportante'"
                label="Aportante"
                v-model="contributor"
                :items="contributors"
                item-text="name"
                item-value="_id"
                :disabled="blockMode"
            ></v-autocomplete>

            <v-autocomplete
                v-if="collectionType === 'Afiliado'"
                label="Afiliado"
                v-model="affiliate"
                :items="affiliates"
                item-text="fullName"
                item-value="_id"
                :disabled="blockMode"
            ></v-autocomplete>

            <v-text-field
                v-model="payrollCode"
                :counter="payrollCodeMax"
                :maxlength="payrollCodeMax"
                :rules="payrollCodeRules"
                label="Código de Planilla"
                required
            ></v-text-field>

            <v-file-input
                show-size
                :rules="payrollFileRules"
                truncate-length="15"
                accept=".pdf"
                label="Planilla de Pago"
                ref="fileInput"
                v-model="payrollFile"
                required
            ></v-file-input>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
    data() {
        return {
            blockMode: false,
            mountedFinish: false,
            valid: false,
            collectionType: '',
            collectionTypes: ['Aportante', 'Afiliado'],
            collectionTypeRules: [
                v => !!v || 'El Tipo de Recaudo es requerido',
            ],
            contributor: '',
            contributors: [],
            affiliate: '',
            affiliates: [],
            payrollCode: '',
            payrollCodeMax: 1,
            payrollCodeRules: [
                v => !!v || 'El Código es requerido'
            ],
            payrollFileRules: [
                v => !!v || 'El Archivo de Planilla es requerido'
            ],
            payrollFile: ''
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.collectionType = value.collectionType;
                        this.contributor = value.contributor;
                        this.affiliate = value.affiliate;
                        this.blockMode = true;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                payrollCode: this.payrollCode,
                payrollFile: this.payrollFile
            }
        })
    },
    async mounted() {
        const contributorData = await store.getData("contributor");
        const branchData = await store.getData("branch");
        const affiliateData = await store.getData("affiliate");
        const schemaData = await store.getData("schema");

        contributorData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        contributorData.forEach(contributor => {
            if (contributor.office === this.$route.params._id) {
                this.contributors.push(contributor); 
            }
        });

        affiliateData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        affiliateData.forEach(affiliate => {
            branchData.forEach(branch => {
                if (branch._id === affiliate.branch) {
                    affiliate.contributor = branch.contributor;
                }
            });
            contributorData.forEach(contributor => {
                if (contributor._id === affiliate.contributor) {
                    affiliate.office = contributor.office;
                }
            });
            if (affiliate.office === this.$route.params._id) {
                affiliate.fullName = affiliate.ID + ' - ' + affiliate.name;
                this.affiliates.push(affiliate); 
            }
        });

        schemaData.forEach(schema => {
            if (schema.store === "collection") {
                this.payrollCodeMax = schema.data.payrollCode.max;
            }
        });

        this.mountedFinish = true;
    }
}
</script>